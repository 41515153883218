import './css/benefits.css';

import Benefit1 from './images/highquality.webp';
import Benefit2 from './images/fast.webp';
import Benefit3 from './images/callcenter.webp';



function Benefits(){
    return(
        <>
            <section className='benefits'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='benefit'>
                                <img src={Benefit1} alt="benefit img" />
                                <div className='text'>
                                    <i class="las la-award"></i>

                                    <h2> جودة عالية وثقة </h2>
                                    <p>  توريدات الإبداع ملتزمة بتقديم مواد البناء والسباكة عالية الجودة </p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='benefit'>
                                <img src={Benefit2} alt="benefit img" />
                                <div className='text'>
                                    <i class="las la-history"></i>

                                    <h2> توفير الوقت والجهد </h2>
                                    <p> نحن نقدم خدمة توصيل إلى الموقع </p>
                                </div>
                            </div>
                        </div>


                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='benefit'>
                                <img src={Benefit3} alt="benefit img" />
                                <div className='text'>
                                    <i class="las la-headset"></i>

                                    <h2> استشارات فنية </h2>
                                    <p> فريق الخبراء في شركة "توريدات الإبداع" دائمًا متاح  </p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default Benefits;