import './css/products.css';

import Product1 from './images/products/building.webp';
import Product2 from './images/products/plumbing.webp';
import Product3 from './images/products/doors.webp';

import Lines from './images/pngbacks/lines.webp';



function Products(){
    return(
        <>
            <section className='products'>
                <img src={Lines} alt="lines" />
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='products-header'>
                                <h2> منتجاتنا المتميزة <i class="las la-tools"></i></h2>
                                <h1>  نفتخر بتقديم مجموعة متنوعة من منتجات مواد البناء والسباكة عالية الجودة </h1>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='product'>
                                <img src={Product1} alt="products image" />
                                <div className='text'>
                                    <div>
                                        <h3> توريدات الإبداع <span></span> </h3>
                                        <h1>  مواد البناء  </h1>
                                        <p> الأسمنت - الحديد والصلب - الطوب والبلوك - الخرسانة الجاهزة</p>
                                    </div>

                                    <a href="tel:+966538709799"> 
                                        <i class="las la-phone-volume"></i>
                                    </a>
                                </div>
                            </div>
                        </div>



                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='product'>
                                <img src={Product2} alt="products image" />
                                <div className='text'>
                                    <div>
                                        <h3> توريدات الإبداع <span></span> </h3>
                                        <h1>  مواد السباكة </h1>
                                        <p> الأنابيب والتجهيزات - الصمامات -  أجهزة الحمامات - مواد تصريف المياه</p>
                                    </div>

                                    <a href="tel:+966538709799"> 
                                        <i class="las la-phone-volume"></i>
                                    </a>
                                </div>
                            </div>
                        </div>




                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='product'>
                                <img src={Product3} alt="products image" />
                                <div className='text'>
                                    <div>
                                        <h3> توريدات الإبداع <span></span> </h3>
                                        <h1>   مواد تشطيب البناء </h1>
                                        <p>  الأبواب والنوافذ - الأرضيات والجدران</p>
                                    </div>

                                    <a href="tel:+966538709799"> 
                                        <i class="las la-phone-volume"></i>
                                    </a>
                                </div>
                            </div>
                        </div>



                        
                        

                    </div>
                </div>
            </section>
        </>
    )
}

export default Products;