import './css/footer.css';

import Service1 from './images/fast.webp';
import Service2 from './images/callcenter.webp';

import Logo from './images/logo.png';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect,useRef  } from "react";




function Footer(){
    return(
        <>
            <footer>
                <div className='overlay'></div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <div className='logo'>
                                    <img src={Logo} alt="logo" />
                                </div>

                                <p> إذا كنت بحاجة إلى مزيد من المعلومات حول منتجاتنا أو كنت ترغب في طلب منتجات معينة، فلا تتردد في الاتصال بنا. فريق "توريدات الإبداع" مستعد لمساعدتك وتلبية احتياجاتك في مجال مواد البناء والسباكة</p>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <h1>  خدماتنا المميزة </h1>

                                <div className='service'>
                                    <img src={Service1} alt="service img"/>
                                    <div>
                                        <h4> توريد  سريع وفعال  </h4>
                                        <p> احتياجات عملائنا في الوقت المحدد </p>
                                    </div>
                                </div>

                                <div className='service' alt="service img">
                                    <img src={Service2} />
                                    <div>
                                        <h4> استشارات فنية  </h4>
                                        <p>  اختيار المواد الأنسب لمشروعك </p>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <h1>  روابط سريعة </h1>

                                <ul> 
                                <li>
                                    <a href="/">
                                        الرئيسية
                                    </a>
                                </li>

                                <li>
                                    <a href="#about-us">
                                        عن الشركة
                                    </a>
                                </li>

                                <li>
                                    <a href="#services">
                                        خدماتنا
                                    </a>
                                </li>

                                <li>
                                    <a href="#contact-us">
                                        تواصل معنا
                                    </a>
                                </li>
                                </ul>

                            </div>
                        </div>


                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <h1>  الواتساب </h1>

                                <p> راسلناالان عبر الواتساب </p>

                                <a href="https://wa.me/+966538709799"> 
                                    whatsapp <i class="lab la-whatsapp"></i>
                                </a>

                            </div>
                        </div>


                        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='copy-right'>
                                <div className='socials'>
                                    <a href="#"> 
                                        <i class="lab la-facebook-f"></i>
                                    </a>

                                    <a href="#"> 
                                        <i class="lab la-twitter"></i>
                                    </a>

                                    <a href="#"> 
                                        <i class="lab la-linkedin-in"></i>
                                    </a>
                                    <a href="#"> 
                                        <i class="lab la-instagram"></i>
                                    </a>
                                </div>

                                <h5> جميع الحقوق محفوظة &copy; توريدات الابداع  </h5>
                            </div>
                        </div>



                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;