import './css/steps.css';

function Steps(){
    return(
        <>
            <section className='steps'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='steps-header'>
                                <h2> كيفية التواصل معنا <i class="las la-tools"></i></h2>
                                <h1> يمكنك التواصل مع فريق "توريدات الإبداع" بسهولة. </h1>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='step'>
                                <i class="lab la-wpforms"></i>
                                <h4>  ملء نموذج الاتصال على موقعنا </h4>
                                <p> يمكنك زيارة موقعنا على الإنترنت وملء نموذج الاتصال الخاص بنا </p>
                                <span> 03</span>
                            </div>
                        </div>


                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='step'>
                                <i class="las la-envelope"></i>
                                <h4> إرسال استفسار عبر البريد الإلكتروني </h4>
                                <p> إذا كنت تفضل البريد الإلكتروني، يمكنك مراسلتنا عبر عنوان البريد الإلكتروني الخاص بنا </p>
                                <span> 02</span>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='step'>
                                <i class="las la-phone-volume"></i>
                                <h4>  الاتصال بنا عبر الهاتف </h4>
                                <p> يمكنك الاتصال بفريق الدعم الخاص بنا عبر الهاتف في أوقات العمل الرسمية </p>
                                <span> 01</span>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Steps;