import './css/landing.css';


import {Swiper,SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Autoplay,EffectCoverflow,Navigation,Pagination} from 'swiper';

import LandingImg1 from './images/landing-1.webp';
import LandingImg2 from './images/servicesback.webp';
import LandingImg3 from './images/footerback.webp';



function Landing(){
    return(
        <>
            <section className='landing'>

                
                <Swiper effect={'coverflow'} centeredSlides={true} grabCursor={true} spaceBetween={50} slidesPerView={1} navigation={{
                        nextEl: '.next-btn',
                        prevEl: '.prev-btn',
                        }} pagination={true}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                    }}
                    autoplay={{
                        delay: 30000,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay,EffectCoverflow,Navigation,Pagination]}
                    >

                <SwiperSlide>

                    <div className='landing-content'>
                        <img src={LandingImg1} alt="landing section image" />
                        <div className='overlay'></div>
                        <div className='text'>
                            <h2>  مرحبًا بك في توريدات الإبداع <i class="las la-dot-circle"></i></h2>
                            <h1>  رائدة في مجال توريد مواد البناء والسباكة </h1>

                            <p>
                                نحن نفتخر بتقديم حلول متكاملة ومواد عالية الجودة لمشاريع البناء والتجديد في جميع أنحاء المملكة. 
                            </p>

                            <a href="https://wa.me/+966538709799">
                                تواصل معنا الان
                            </a>
                        </div>
                    </div>

                </SwiperSlide>


                <SwiperSlide>

                <div className='landing-content'>
                    <img src={LandingImg2} alt="landing section image" />
                    <div className='overlay'></div>
                    <div className='text'>
                        <h2>  مرحبًا بك في توريدات الإبداع <i class="las la-dot-circle"></i></h2>
                        <h1>  رائدة في مجال توريد مواد البناء والسباكة </h1>

                        <p>
                            نحن نفتخر بتقديم حلول متكاملة ومواد عالية الجودة لمشاريع البناء والتجديد في جميع أنحاء المملكة. 
                        </p>

                        <a href="https://wa.me/+966538709799">
                            تواصل معنا الان
                        </a>
                    </div>
                </div>

                </SwiperSlide>


                
                <SwiperSlide>

                <div className='landing-content'>
                    <img src={LandingImg3} alt="landing section image" />
                    <div className='overlay'></div>
                    <div className='text'>
                        <h2>  مرحبًا بك في توريدات الإبداع <i class="las la-dot-circle"></i></h2>
                        <h1>  رائدة في مجال توريد مواد البناء والسباكة </h1>

                        <p>
                            نحن نفتخر بتقديم حلول متكاملة ومواد عالية الجودة لمشاريع البناء والتجديد في جميع أنحاء المملكة. 
                        </p>

                        <a href="https://wa.me/+966538709799">
                            تواصل معنا الان
                        </a>
                    </div>
                </div>

                </SwiperSlide>


                </Swiper>

                <div className='buttons'>
                    <button className='prev-btn'><i class="las la-arrow-left"></i></button>
                    <button className='next-btn'><i class="las la-arrow-right"></i></button>
                </div>


            </section>
        </>
    )
}

export default Landing;