import './css/solutions.css';

import Logo from './images/logo.png';
import SolutionsImg from './images/solutions.webp';
import Lines from './images/pngbacks/lines.webp';

function Solutions(){
    return(
        <>
            <section className='solutions'>
                <img src={Logo} alt="logo"/>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='service-header'>
                                <h1> تواصل معنا اليوم </h1>
                                <p> لمعرفة المزيد حول كيف يمكننا مساعدتك في تحقيق أهدافك في مجال مواد البناء والسباكة </p>
                                <a href="tel:+966538709799"> إتصل بنا الان  </a>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='images'>
                                <img src={SolutionsImg} alt="solutions img" />
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='text'>
                                <img src={Lines} alt="lines" />
                                <h2> حلول متكاملة لا تضاهى </h2>
                                <h1> نسعى دائمًا لتقديم حلول متكاملة وفعّالة  </h1>
                                <p> نتفهم تمامًا التحديات التي تواجهها في مشاريعك، ونقدم مجموعة من الحلول لجعل تلك التحديات أسهل علىكم. إليك بعض الحلول التي نقدمها </p>

                                <div className='solution'>
                                    <i class="las la-tags"></i>
                                    <div>
                                        <h3> تخصيص الطلبات </h3>
                                        <p> نحن نفهم أن مشاريع البناء قد تتطلب موادًا مخصصة</p>
                                    </div>
                                </div>

                                <div className='solution'>
                                    <i class="las la-seedling"></i>
                                    <div>
                                        <h3> توريد مستدام </h3>
                                        <p> نقدم مواد صديقة للبيئة والتي تلبي المعايير البيئية الصارمة </p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default Solutions;