import './css/map.css';

function Maps(){
    return(
        <>
            <section className='map'>
                <div className='map-content'>
                
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14944229.614057263!2d55.72291710448779!3d23.872111016270118!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15e7b33fe7952a41%3A0x5960504bc21ab69b!2z2KfZhNiz2LnZiNiv2YrYqQ!5e0!3m2!1sar!2seg!4v1695104866246!5m2!1sar!2seg" width="100%" height="350"  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

                    <div className='contact-info'>
                        <div className='info'>
                            <i class="las la-map-marker"></i>
                            <div>
                                <h3> عنوان المكتب الرئيسي </h3>
                                <a href="#"> المملكة العربية السعودية </a>
                            </div>
                        </div>

                        <div className='info'>
                            <i class="las la-mobile"></i>
                            <div>
                                <h3> ابقى على اتصال  </h3>
                                <a href="tel:+966538709799"> +966538709799 </a>
                            </div>
                        </div>


                        <div className='info'>
                            <i class="lar la-envelope"></i>
                            <div>
                                <h3> راسلنا عبر البريد </h3>
                                <a href="mailto:gm@creativesuppliess.com"> gm@creativesuppliess.com </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Maps;