import './App.css';
import { Routes, Route } from "react-router-dom";



import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

// comps

import UpperNav from './components/UpperNav';
import Navbar from './components/Navbar';
import Landing from './components/Landing';
import About from './components/About';
import Benefits from './components/Benefits';
import Services from './components/Services';
import Solutions from './components/Solutions';
import Steps from './components/Steps';
import Products from './components/Products';
import Contact from './components/Contact';
import Map from './components/Map';
import Footer from './components/Footer';


function App() {




return (
    <div className="App">



            <Routes>
            

            <Route path="/" element={
                    <>
                        <UpperNav />
                        <Navbar />
                        <Landing />
                        <About />
                        <Benefits />
                        <Services />
                        <Solutions />
                        <Steps />
                        <Products />
                        <Contact/>
                        <Map />
                        <Footer />

                    </>
                } />

            </Routes>

        
    </div>
);
}

export default App;
