import './css/services.css';

function Services(){
    return(
        <>
            <section id="services" className='services'>
                <div className='overlay'></div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='services-header'>
                                <div className='left'>
                                    <h2> خدمات توريدات الإبداع <i class="las la-tools"></i></h2>
                                    <h1> تعرف على خدماتنا الان </h1>
                                </div>

                                <div className='right'>
                                    <p> 
                                        عندما تختار التعامل مع "توريدات الإبداع"، فإنك تختار شريكًا يهتم بنجاح مشروعك ويقدم لك الدعم والخدمة التي تستحقها. دعنا نكون جزءًا من رحلتك نحو البناء والتجديد بنجاح.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='service'>
                                <div className='icon'>
                                    <i class="las la-shipping-fast"></i>
                                    <span></span>
                                </div>
                                <h2> توريد  سريع وفعال </h2>
                                <p> نحن ندرك أهمية الوقت في مشاريع البناء. لذا، نقدم توريدات سريعة وفعالة تلبي احتياجات عملائنا في الوقت المحدد.</p>
                                <a href="tel:+966538709799">
                                    <i class="las la-phone-volume"></i>
                                </a>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='service'>
                                <div className='icon'>
                                    <i class="las la-headset"></i>
                                    <span></span>
                                </div>
                                <h2>   استشارات فنية </h2>
                                <p> 
                                    فريق الخبراء في توريدات الإبداع متاح دائمًا لتقديم استشارات فنية متخصصة لمساعدتك في اختيار المواد الأنسب لمشروعك.
                                </p>
                                <a href="tel:+966538709799">
                                    <i class="las la-phone-volume"></i>
                                </a>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='service'>
                                <div className='icon'>
                                <i class="las la-truck"></i>
                                    <span></span>
                                </div>
                                <h2>  توصيل إلى الموقع </h2>
                                <p> 
                                    نقوم بتوفير خدمة التوصيل إلى الموقع لجعل عمليات البناء أكثر سهولة ويسرًا.
                                </p>
                                <a href="tel:+966538709799">
                                    <i class="las la-phone-volume"></i>
                                </a>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='service'>
                                <div className='icon'>
                                <i class="las la-hand-holding-usd"></i>
                                    <span></span>
                                </div>
                                <h2> أسعار تنافسية </h2>
                                <p>نحن نقدم منتجات ذات جودة عالية بأسعار تنافسية. نحن نهدف إلى توفير أقصى قيمة ممكنة لعملائنا من خلال توفير أفضل العروض والصفقات.</p>
                                <a href="tel:+966538709799">
                                    <i class="las la-phone-volume"></i>
                                </a>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default Services;