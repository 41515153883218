import './css/navbar.css';

import { Link as RouterLink } from 'react-router-dom';
import { useEffect,useRef  } from "react";
import $ from 'jquery';

function Navbar(){


    
    useEffect(() => {
        $("#open-nav").on("click", function() {
            $(".nav-elements").slideDown(400);
            $(".nav-content").addClass("active");


            $(".nav-elements").css({display: "flex"});
        });

        $("#close-nav").on("click", function() {
            $(".nav-elements").slideUp(400);
            $(".nav-content").removeClass("active");

        });

        if ($(window).width() < 950) {
            $(".nav-elements ul li").on("click", function() {
                $(".nav-elements").slideUp(400);
                $(".nav-content").removeClass("active");

            });
        }
    const loadingElement = document.getElementById("loading");
    if (loadingElement) {
        loadingElement.remove();
    }
    }, []);
    



    return(
        <>
            <nav>
                <div className='container-fluid'>
                    <div className='nav-content'>
                        
                        
                    <button id="open-nav"> <i className="las la-bars"></i> </button>
                        <div className="nav-elements">
                        <button id="close-nav"> <i className="las la-times"></i> </button>

                            <ul>
                                <li>
                                    <a href="/">
                                        الرئيسية
                                    </a>
                                </li>

                                <li>
                                    <a href="#about-us">
                                        عن الشركة
                                    </a>
                                </li>

                                <li>
                                    <a href="#services">
                                        خدماتنا
                                    </a>
                                </li>

                                <li>
                                    <a href="#contact-us">
                                        تواصل معنا
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <a href="https://wa.me/+966538709799">
                            <i class="las la-map-marked-alt"></i>
                            المملكة العربية السعودية
                        </a>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar;