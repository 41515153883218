import './css/about.css';

import Logo from './images/logo.png';
import AboutLgImg from './images/about-lg_0.webp';
import AboutSmImg from './images/about-sm_1.webp';
import Lines from './images/pngbacks/lines.webp';


function About(){
    return(
        <>
            <section id="about-us" className='about'>
                <img src={Lines} alt="lines" />
                <div className='container-fluid'>
                    <div className='row'>


                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='text'>
                                <h2> عن توريدات الأبداع <i class="las la-tools"></i>  </h2>
                                <h1> نحن نسعى دائمًا لتلبية احتياجات عملائنا بكفاءة وجودة لا مثيل لها </h1>
                                <p> مهمتنا هي تقديم مواد بناء وسباكة عالية الجودة بأسعار تنافسية للمساهمة في نجاح مشاريع عملائنا وبناء شراكات طويلة الأمد.</p>

                                <div className='about-benefits'>
                                    <div className='left'>

                                        <div>
                                            <i class="las la-industry"></i>
                                            <div className='info'>
                                                <h3> مواد البناء </h3>
                                                <p> تقدم توريدات الإبداع مجموعة شاملة من مواد البناء عالية الجودة </p>
                                            </div>
                                        </div>

                                        <div>
                                            <i class="las la-wrench"></i>
                                            <div className='info'>
                                                <h3> مواد السباكة </h3>
                                                <p> نقدم مجموعة واسعة من مواد السباكة التي تشمل الأنابيب، والصمامات، وأجهزة الحمامات، ومواد تصريف المياه </p>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='right'>
                                        <div className='precentage'>
                                            <span> </span>
                                            <h5> 98 % </h5>
                                        </div>

                                        <h4> <i class="las la-laugh-beam"></i> عملاء سعداء </h4>
                                    </div>
                                </div>


                                <ul>
                                    <li>
                                        <i class="las la-check"></i>
                                        نحن هنا لتلبية احتياجاتك
                                    </li>
                                    <li>
                                        <i class="las la-check"></i>
                                        جميع منتجاتنا مصنوعة من مواد عالية الجودة تضمن استدامة وأداء ممتاز
                                    </li>
                                </ul>

                                <a href="https://wa.me/+966538709799">
                                    <img src={Logo} alt="logo" />
                                    تواصل معنا الان وأحصل على خدمتك
                                </a>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='images'>
                                <img src={AboutLgImg} alt="about image lg" />
                                <img src={Logo} alt="Logo" />
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default About;