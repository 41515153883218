import './css/uppernav.css';

import Logo from './images/logo.png';
function UpperNav(){
    return(
        <>
            <div className='upper-nav'>
                <div className='container-fluid'>
                    <div className='upper-nav-content'>
                        <div className='logo'>
                            <img src={Logo} alt="Logo" />
                        </div>


                        <div className='contact-info'>
                            <div>
                                <i class="las la-phone-volume"></i>

                                <div className='text'>
                                    <h2> إتصل الان  </h2>
                                    <a href="tel:+966538709799">  +966538709799 </a>
                                </div>
                            </div>

                            <div>
                                <i class="las la-envelope"></i>
                                
                                <div className='text'>
                                    <h2>  راسلنا عبر البريد </h2>
                                    <a href="mailto:gm@creativesuppliess.com">  gm@creativesuppliess.com </a>
                                </div>
                            </div>


                            <div>
                                <i class="las la-clock"></i>
                                
                                <div className='text'>
                                    <h2> مواعيد العمل </h2>
                                    <a href="https://wa.me/+966538709799"> Sat - Thu (08:00 - 16:00) </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpperNav;